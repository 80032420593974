<template>
  <vuestic-widget v-if="user">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class="title">
          <span>Tasks</span>
        </div>
      </div>
    </template>
    <div class="table-wrapper">
      <div class="pb-2 mb-3">
        <div class="d-flex flex-row justify-content-end align-items-center right-filter-wrapper">
          <div class="d-flex">
            <div class="d-flex align-items-center mr-2">
              <multiselect class="filter-select d-inline-block" style="width: 150px;" v-model="selectedStatus"
                :options="statusList" :multiple="false" :close-on-select="true" :clear-on-select="false"
                :preserve-search="true" placeholder="" label="label" track-by="id" :preselect-first="true"
                :show-labels="false" :searchable="false" :allowEmpty="false" @input="onChangeFilter"></multiselect>
            </div>
            <div class="d-flex align-items-center justify-content-end flex-row mr-2">
              <search-input name="searchInput" v-model="searchFilter" @input="onChangeFilter" />
            </div>
            <div class="">
              <button class="btn btn-primary pull-right" @click="onResetFilter()">
                <span>Reset</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="task-table d-block">
        <div class="d-flex justify-content-center" v-if="taskFetching">
          <span class="atom-spinner-wrapper">
            <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)" />
          </span>
        </div>
        <datatable v-else v-bind="this.tableConfig" class="le-datatable" />
      </div>
    </div>
    <!-- Edit Task Modal Starts-->
    <vuestic-modal :isOpen="isOpenModalEdit" @ok="false" @cancel="closeModalEdit" okText="Create" :cancelShown="false"
      :okShown="false">
      <span slot="title">Update Task</span>
      <div v-if="taskSelected" class="d-block">
        <ValidationObserver v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="handleSubmit(updateTask)">
            <div class="row">
              <div class="col-md-12">
                <text-input name="Name" v-model="taskSelected.name" label="Name" />
              </div>
              <div class="col-md-12 mt-4">
                <div class="form-group">
                  <div class="input-group">
                    <textarea v-model="taskSelected.description" name="description" class="form-control w-100"
                      maxlength="299" rows="3"></textarea>
                    <label class="control-label" for="Message">Description</label>
                    <i class="bar"></i>
                  </div>
                </div>
                <SMSLength :text="taskSelected.description" />
              </div>
              <div class="col-md-6 mt-4">
                <div class="form-group">
                  <div class="input-group">
                    <label class="control-label">Date</label>
                    <vuestic-date-picker id="scheduled_at_day_picker" v-model="taskSelected.date"
                      :config="datePickerConfig" />
                    <i class="bar"></i>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="form-group">
                  <div class="input-group">
                    <label class="control-label">Time</label>
                    <vuestic-date-picker id="scheduled_at_time_picker" v-model="taskSelected.time"
                      :config="timePickerConfig" />
                    <i class="bar"></i>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <multiselect v-model="timezoneScheduled" :options="timezones" :multiple="false" :close-on-select="true"
                  :clear-on-select="false" :preserve-search="true" placeholder="Select Timezone" label="label"
                  track-by="label" :preselect-first="true" :show-labels="false">
                </multiselect>
              </div>
              <div class="col-md-12 mt-4">
                <div class="custom-checkbox-wrapper d-inline-block">
                  <label class="checkbox-label">
                    <input type="checkbox" id="confirm-checkbox" v-model="markCompleted" />
                    <span class="checkbox-custom"></span>
                  </label>
                </div>
                <span class="d-inline-block ml-2" style="cursor: pointer" @click="onToggleCheckbox('completed')">Mark it as completed</span>
              </div>
              <div class="col-md-12 mt-3">
                <div class="custom-checkbox-wrapper d-inline-block">
                  <label class="checkbox-label">
                    <input type="checkbox" id="confirm-checkbox" v-model="markClosed" />
                    <span class="checkbox-custom"></span>
                  </label>
                </div>
                <span class="d-inline-block ml-2" style="cursor: pointer" @click="onToggleCheckbox('closed')">Mark it as closed</span>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-md-12 d-flex justify-content-center">
                <button class="btn btn-primary">
                  <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
                  <span v-else>Update</span>
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </vuestic-modal>
    <!-- Edit Task Modal Ends-->
    <vuestic-modal :isOpen="isOpenModalDelete" :hideDefaultActions="true" @cancel="closeModalDelete" :cancelShown="false"
      :okShown="false">
      <span slot="title" class="text-danger font-weight-bold">Delete Task</span>
      <p>Are you sure you want to delete this task <strong>{{ taskSelected ? taskSelected.name : '' }}</strong></p>
      <div class="row mt-5">
        <div class="col-md-12 d-flex justify-content-center">
          <button @click="onDeleteTask()" class="btn btn-danger" :disabled="taskProcessing">
            <atom-spinner v-if="taskProcessing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
            <span v-else>Delete</span>
          </button>
        </div>
      </div>
    </vuestic-modal>
  </vuestic-widget>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import SMSLength from "../../common/SMSLength.vue";
import components from "../../common/tables/comps";
import SearchInput from '../../common/SearchInput'

export default {
  components: {
    SMSLength, SearchInput
  },
  data() {
    return {
      tableConfig: {
        supportBackup: false,
        supportNested: false,
        HeaderSettings: false,
        tblClass: "table-bordered",
        tblStyle: "color: #666",
        pageSizeOptions: [10, 25, 50, 100],
        columns: (() => {
          return [
            { title: "ID", field: "id", tdClass: "center", sortable: true, visible: true, },
            { title: "Number", field: 'number', thClass: "center", tdClass: "center", tdStyle: { fontStyle: "normal" }, tdComp: "TdPhone" },
            { title: "Title", field: "name", thClass: "left", tdClass: "left w-400", sortable: true, visible: true },
            { title: "Status", field: "status_str", thClass: "left", tdClass: "left", sortable: true, visible: true },
            { title: "ExpireAt", field: "expired_at", tdClass: "center", tdComp: "TdTimestamp", sortable: true, visible: true, },
            { title: "", tdComp: 'TaskMore', sortable: false, visible: true },
          ];
        })(),
        data: [],
        total: 0,
        // selection: [],  //if this is present the row selector shows up..
        summary: {},
        query: {},
        // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
        xprops: {
          eventbus: new Vue()
        }
      },
      datePickerConfig: {
        enableTime: false,
        dateFormat: "Y-m-d",
      },
      timePickerConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "G:i K",
      },
      loading: false,
      processing: false,
      isOpenModalDelete: false,
      isOpenModalEdit: false,
      taskSelected: null,
      searchFilter: '',
      selectedStatus: null,
      timezoneScheduled: null,
      markCompleted: false,
      markClosed: false,
      statusList: [
        { id: 0, label: 'All' },
        { id: 1, label: 'Todo' },
        { id: 2, label: 'Completed' },
        { id: 3, label: 'Expired' },
        { id: 4, label: 'Closed' },
      ],
    };
  },

  created() {
    // onEdit
    this.tableConfig.xprops.eventbus.$on('onEdit', row => {
      this.taskSelected = row
      this.initModal()
      this.isOpenModalEdit = true
    })

    this.tableConfig.xprops.eventbus.$on('onDelete', row => {
      this.taskSelected = row
      this.isOpenModalDelete = true
    })

    const offset = (this.taskPage - 1 >= 0 ? this.taskPage - 1 : 0) * this.taskPerPage;
    this.tableConfig.query = {
      offset,
      limit: this.taskPerPage,
    }
  },

  watch: {
    tasks: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.tableConfig.data = newValue;
        }
      },
      deep: true,
    },
    tasksTotal: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.tableConfig.total = newValue;
        }
      },
      deep: true,
    },
    'tableConfig.query': {
      handler() {
        this.loadTasks();
      },
      deep: true
    },
    taskFilter: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.initTable();
        }
      },
      deep: true,
    },
  },

  computed: {
    user() {
      return this.$store.state.auth.user
    },

    timezones() {
      return this.$store.getters["timeZones"];
    },

    ...mapState('task', {
      taskFetching: 'fetching',
      taskDidFetch: 'didFetch',
      taskProcessing: 'processing',
      taskPage: 'page',
      taskPerPage: 'perPage',
      taskFilter: 'filter',
      tasks: 'data',
      tasksTotal: 'total'
    })
  },
  mounted() {
    this.selectedStatus = this.statusList[1]
    if (this.user.role === 'BusinessAdmin') {
      this.tableConfig.columns.splice(4, 0, { title: "User", field: "created_by", thClass: "left", tdClass: "left", sortable: true, visible: true })
    }
  },
  methods: {
    initModal() {
      if (this.taskSelected) {
        this.markCompleted = this.taskSelected.status === 2
        this.markClosed = this.taskSelected.status === 4
        this.timezoneScheduled = this.timezones.find(
          (item) => item.value === this.taskSelected.timezone
        );
      }
    },

    initTable() {
      this.tableConfig = {
        ...this.tableConfig,
        data: [],
        total: 0,
        query: {
          ...this.tableConfig.query,
          offset: 0,
          limit: this.taskPerPage
        }
      }
    },

    onToggleCheckbox(type) {
      if (type === 'completed') {
        this.markCompleted = !this.markCompleted
        this.markClosed = false
      } else {
        this.markCompleted = false
        this.markClosed = !this.markClosed
      }
    },

    loadTasks() {
      const { query } = this.tableConfig;
      const page = query.offset ? Math.floor(query.offset / query.limit) + 1 : 1;
      const param = {
        page,
        per_page: query.limit,
        order: query.order,
        sort: query.sort,
        search: this.searchFilter,
        status: this.selectedStatus ? this.selectedStatus.id: 1,
      }

      this.$store.dispatch('task/fetch', param)
    },

    onResetFilter() {
      this.selectedStatus = this.statusList[1]
      this.searchFilter = null
      this.onChangeFilter()
    },

    onChangeFilter() {
      const payload = {
        keyword: this.searchFilter,
        status: this.selectedStatus ? this.selectedStatus.id : 1,
      }
      this.$store.commit('task/updateFilter', payload)
    },

    closeModalDelete() {
      this.isOpenModalDelete = false
    },

    closeModalEdit() {
      this.isOpenModalEdit = false
    },

    onDeleteTask() {
      this.$store
        .dispatch('task/delete', this.taskSelected.id)
        .then(() => {
          this.isOpenModalDelete = false;
          this.loadTasks();
        })
        .catch((err) => {
        })
    },

    updateTask() {
      const param = {
        id: this.taskSelected.id,
        data: {
          ...this.taskSelected,
          timezone: this.timezoneScheduled,
          completed: this.markCompleted,
          closed: this.markClosed,
        }
      }

      this.$store
        .dispatch('task/update', param)
        .then(() => {
          this.isOpenModalEdit = false;
          this.loadTasks();
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },

};
</script>
<style lang="scss" scoped>
.template-list {
  max-height: 400px;
  overflow-y: auto;
  padding: 20px 30px;
}

.template-box {
  cursor: pointer;
  padding: 1rem 2rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  background: #FFF;
  margin-bottom: 10px;
  border-radius: 10px;

  .name {
    display: block;
    color: #00629f;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .desc {
    font-size: 14px;
  }

  &:hover {
    border: 2px solid #00629f;
  }
}
</style>
